import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="318" height="11" viewBox="0 0 318 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M58.979 9.59711C51.627 8.77612 3.83219 11.1114 1.09374 10.9958C-1.80659 10.7283 1.70079 4.28803 4.20991 3.26636C5.74776 2.64605 5.63984 3.09608 18.9004 2.64605C18.9004 2.64605 38.8925 1.98926 63.4711 1.223C63.4711 1.223 102.956 0.231727 157.32 0.000633187C161.597 -0.0176111 158.211 0.365518 163.242 0.231727C173.967 -0.0419367 197.305 0.474984 205.425 0.256053C211.941 0.0796917 213.344 0.0675293 219.563 0.402007C223.839 0.633101 259.722 1.20475 263.702 0.858114C264.673 0.772974 265.375 0.827706 265.361 0.967579C265.644 0.979742 289.157 1.8129 289.265 1.94061C289.427 2.08656 290.142 2.17778 290.83 2.1048C292.098 1.96493 317.527 2.39671 317.864 2.82241C318.835 4.0387 314.397 8.84302 311.281 9.95592C308.057 11.1053 292.422 9.08627 278.608 9.43291C214.18 7.6389 214.68 8.095 203.915 8.33218C202.431 7.88823 196.576 8.84302 193.096 8.08284C191.639 7.76052 181.171 7.6389 178.823 7.91256C178.324 7.97337 174.588 7.96121 169.192 7.91864C166.494 7.9004 163.377 7.86999 160.045 7.84566C155.594 7.80917 121.909 7.64498 117.687 8.35042C106.329 7.64498 69.7844 9.17141 58.979 9.59711Z" fill="#FFBA00" />
    </svg>

  </span>
)

export default titleUnderline
