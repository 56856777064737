import React from "react"
import { Link } from "gatsby"

const BannerCTA = ({ title, text, link, textButton }) => {
  return (
    <div className="container-banner-cta">
      <p className="container-banner-cta-title">{title}</p>
      <p className="container-banner-cta-text">{text}</p>
      <Link
        className="container-banner-cta-button"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {textButton}
      </Link>
    </div>
  )
}
export default BannerCTA
